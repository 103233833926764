import _ from 'lodash'
import { boot } from 'quasar/wrappers'
import BootMixins from 'mixins/boot'
import GlobalMixins from 'hc-core/mixins/global.js'
import { intlInit } from 'hc-core/composables/intl'
import HCButton from 'hc-core/components/buttons/hc-button'
import HCLoading from 'hc-core/components/common/hc-loading'
import AppContent from 'hc-core/components/common/app-content'
import HCBigButton from 'hc-core/components/buttons/hc-big-button'
import ActionTooltip from 'hc-core/components/tooltips/action-tooltip'

// Refactored common > ui components
import {
  HcButton,
  HcText,
  HcTooltip
} from 'hc-core'

export default boot(async ({ app, store }) => {
  // Components
  app.component('HcButton', HcButton)
  app.component('HcText', HcText)
  app.component('HcTooltip', HcTooltip)

  // Components
  app.component('HCButton', HCButton)
  app.component('HCLoading', HCLoading)
  app.component('AppContent', AppContent)
  app.component('HCBigButton', HCBigButton)
  app.component('ActionTooltip', ActionTooltip)

  // Mixins
  app.mixin(BootMixins)
  app.mixin(GlobalMixins)

  // Prototypes
  app.config.globalProperties.$_ = _
  app.config.globalProperties.$uData = store.getters['auth/uData'] // Meant to be gradually replaced by uElements
  app.config.globalProperties.$uElements = store.getters['auth/uElements'] // Meant to replace gradually uData

  // Intl setup
  await intlInit({ app, store })
})
