import stelace from 'hc-core/composables/stelace'
import { companyTeamNumber } from 'hc-core/composables/misc.js'
import _, { get, map, set, uniqWith, isEqual, find, cloneDeep, omit } from 'lodash'

// ##### Generic calls #####

export async function create (context, { attrs }) {
  return await stelace.assets.create(attrs)
}

export async function read (context, { id }) {
  return await stelace.assets.read(id)
}

export async function getBySlug (context, { slug }) {
  return await stelace.forward.get(`assets/getbyslug/${slug}`)
}

// ##### Specific calls #####

export async function createTransaction ({ dispatch }, { asset, transition, platformData = {}, metadata = {}, startDate, endDate } = {}) {
  const transactionAttrs = {
    assetId: asset.id,
    platformData,
    metadata
  }
  if (startDate) transactionAttrs.startDate = startDate
  if (endDate) transactionAttrs.endDate = endDate

  let transaction = await stelace.transactions.create(transactionAttrs)

  // const message = await stelace.messages.create({
  //   content: ' ',
  //   topicId: transaction.id,
  //   receiverId: transaction.ownerId,
  //   metadata: {
  //     isHiddenMessage: true
  //   }
  // })

  if (transition !== null) {
    transaction = await dispatch('asset/createTransactionTransition', {
      transactionId: transaction.id,
      transitionName: transition
    }, { root: true })
  }

  return {
    transaction,
    // message
  }
}

export async function createTransactionTransition (context, { transactionId, transitionName, data }) {
  return await stelace.transactions.createTransition(transactionId, { name: transitionName, data })
}

// Show currentUser assets if userId is not specified
export async function fetchCompanyOffers ({ commit, rootGetters }, { userId, validated = null, active = null, company = null } = {}) {
  // offerCategoriesIds.push(rootGetters['common/defaultOfferCategoryId'])
  const ownerId = userId || (rootGetters['auth/currentUser'] || {}).id
  if (!ownerId) return []

  const maxNbResultsPerPage = 100 // retrieve all assets at once
  const req = { ownerId, assetTypeId: rootGetters['common/offerTypeId'], nbResultsPerPage: maxNbResultsPerPage, validated: validated, active: active }
  if (validated !== null) req.validated = validated
  if (active !== null) req.active = active
  let offers = await stelace.assets.list(req)
  if (company !== null) {
    for (const offer of offers) {
      offer.company = company
    }
  }

  offers = offers.filter((offer) => offer.categoryId !== rootGetters['common/defaultOfferCategoryId'])
  commit('asset/setCompanyOffers', { offers }, { root: true })
  return offers
}

export async function fetchCompaniesOffers ({ commit }, { ownerIds = [], active = true, validated = true }) {
  const offers = await stelace.forward.get('users/offers', {
    ownerId: ownerIds,
    nbResultsPerPage: 1000,
    defaultOffer: false,
    active,
    validated
  })
  commit('asset/setCompanyOffers', { offers: offers.results }, { root: true })
  return offers.results
}

export async function fetchGroupCompanies (context, id) {
  return await stelace.search.list({ customAttributes: { group: id } })
}

export async function fetchFullCompany ({ rootGetters, commit, dispatch }, { slug }) {
  // Setup
  let offers = []
  let company = null
  let isGroup = false
  let activeLocations = []

  // Company
  company = await dispatch('search/companyBySlug', { slug }, { root: true })

  // Implantations
  if (company.implantations.length === 0) company.implantations.push(cloneDeep(company))

  // Offers
  // Check if not truc root company of a group
  const tempoGroup = await dispatch('asset/fetchGroupCompanies', company.id, { root: true })
  isGroup = company.assetTypeId === rootGetters['common/brandTypeId'] || get(company, 'customAttributes.group', null) !== null || (rootGetters['asset/groupCompanies'].length > 1 && get(rootGetters, '[0].customAttributes.group') === get(company, 'id')) || tempoGroup.length > 0
  if (isGroup) {
    await dispatch('asset/fetchGroupCompanies', company.id, { root: true })
    offers = await dispatch('asset/fetchCompaniesOffers', { ownerIds: map(company.implantations, 'ownerId') }, { root: true })
    await dispatch('rating/fetchTestimonials', { assetId: map(company.implantations, 'id') }, { root: true })
  } else {
    if (company.implantations.length > 1 && get(company, 'platformData.options.regroupOffers', false)) {
      offers = await dispatch('asset/fetchCompaniesOffers', { ownerIds: map(company.implantations, 'ownerId') }, { root: true })
    } else if (get(company, 'id', null) !== get(rootGetters['asset/companyOffers'], '[0].ownerId')) {
      offers = await dispatch('asset/fetchCompanyOffers', { userId: company.ownerId, active: true, validated: true, company: company }, { root: true })
    }
    await dispatch('rating/fetchTestimonials', { assetId: company.id }, { root: true })
  }
  offers = offers.filter(offer => offer.active && offer.validated)
  activeLocations = _(company.implantations)
    .flatMap((company) =>
      map(company.locations, (loc) => ({
        ...loc,
        name: company.name,
        companySlug: get(company, 'customAttributes.slug', null),
        hideLink: get(company, 'customAttributes.group', false),
        company: omit(company, 'locations')
      }))
    ).value()
  set(company, 'metadata._team.displayPeople', companyTeamNumber({ rootGetters }, { company }))

  // Handle brand pages
  if (company && company.assetTypeId === rootGetters['common/brandTypeId']) company = await dispatch('asset/formatBrandCompany', { company }, { root: true })

  const fullCompany = {
    offers,
    company,
    isGroup,
    activeLocations,
    implantations: get(company, 'implantations', []),
  }
  await commit('asset/setCompanyOffers', { offers }, { root: true })
  await commit('asset/setCompany', { company }, { root: true })
  await commit('asset/setIsGroup', { isGroup }, { root: true })
  await commit('asset/setActiveLocations', { activeLocations }, { root: true })
  await commit('asset/setImplantations', { implantations: get(company, 'implantations', []) }, { root: true })
  return fullCompany
}

// Only used in fetchFullCompany / TODO : find a way to get rid of it
export async function formatBrandCompany (context, { company }) {
  // Array fields
  // ['customAttributes.tools', 'customAttributes.categories', 'locations', 'offers', 'metadata._files', 'metadata._public.links', 'metadata._public.testimonialsOrder', 'metadata._team.advantages'].forEach((field) => {
  //   set(company, field, uniqWith(flatMap(company.implantations, (imp) => { return get(imp, field, []) }), isEqual))
  // })
  // Array fields
  for (const field of ['customAttributes.tools', 'customAttributes.categories', 'locations', 'offers', 'metadata._files', 'metadata._public.links', 'metadata._public.testimonialsOrder', 'metadata._team.advantages']) {
    let resultArr = get(company, field, [])
    for (const imp in company.implantations) if (get(imp, field, false)) resultArr.push(get(imp, field))
    if (field === 'offers') resultArr = resultArr.filter(offer => offer.categoryId !== context.rootGetters['common/defaultOfferCategoryId'])
    if (resultArr.length) set(company, field, uniqWith(resultArr, isEqual))
  }

  // Parity
  let parity = null
  company.implantations.forEach((imp) => {
    if (get(imp, 'metadata._team.parity', false)) { // Has filled these fields in v2
      parity = Math.round(imp.metadata._team.parity)
    } else if (imp.metadata._team.womanQty && imp.metadata._team.manQty) {
      const totalPersons = parseInt(imp.metadata._team.womanQty) + parseInt(imp.metadata._team.manQty)
      parity = Math.round(parseInt(imp.metadata._team.womanQty) / totalPersons * 100)
    }
  })
  set(company, 'metadata._team.parity', parity)

  // Team members
  set(company, 'metadata._team.displayPeople', companyTeamNumber(context, { company }))

  // Unique fields
  if (get(company, 'platformData.options.reference')) {
    const ref = find(company.implantations, { id: company.platformData.options.reference })
    if (get(ref, 'metadata._files.cover', false)) set(company, 'metadata._files.cover', ref.metadata._files.cover)
    if (get(ref, 'metadata._files.logo', false)) set(company, 'metadata._files.logo', ref.metadata._files.logo)
  }
  return company
}
