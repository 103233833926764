import { get } from 'lodash'

export function companyOffers (state) {
  return state.companyOffers
}

export function activeImplantations (state) {
  return state.implantations
}

export function activeLocations (state) {
  return state.activeLocations
}

export function isGroup (state) {
  return state.isGroup
}

export function activeCompany (state) {
  return state.company
}

export function groupCompanies (state) {
  return [state.company]
}

export function applications (state, getters, rootState, rootGetters) {
  const applications = get(rootGetters, '[`auth/userOrganizations`].applications', [])
  return {
    all: applications,
    new: applications.filter(a => a.status === 'new' || a.status === 'restricted'),
    pending: applications.filter((a) => {
      return a.status === 'pending'
    }),
    refused: applications.filter((a) => {
      return a.status === 'refused'
    }),
    meeting: applications.filter((a) => {
      return a.status === 'meeting'
    }),
    recruited: applications.filter((a) => {
      return a.status === 'recruited'
    }),
    custom: applications.filter((a) => {
      return a.status === 'custom'
    })
  }
}

export function applicantProfile (state) {
  return get(state, 'applicantProfile', null)
}
